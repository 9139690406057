<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary dark src="@/assets/img/bgDrawer.png">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/logos/one-logo.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">Our Nation Express</v-list-item-title>
            <v-list-item-subtitle>Courier Service</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item v-for="([icon, text, link], i) in items" :key="i" link @click="handleClick(link)">
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
              }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :color="color" :flat="flat" dark class="px-15" :class="{ expand: flat }">
      <v-toolbar-title>
        <v-img src="@/assets/logos/one-logo.png" max-width="50px" />
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-4" v-if="isXs" />
      <div v-else>
        <v-btn text @click="onHomeClick">
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn text @click="onAboutClick">
          <span class="mr-2">About Us</span>
        </v-btn>
        <v-btn text @click="onFaqClick">
          <span class="mr-2">FAQ</span>
        </v-btn>
        <v-btn text @click="onRatesClick">
          <span class="mr-2">Rates</span>
        </v-btn>
        <v-btn rounded outlined text @click="onLoginClick">
          <span class="mr-2">Log In</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "Home", "onHomeClick"],
      ["mdi-information-outline", "About Us", "onAboutClick"],
      ["mdi-comment-question-outline", "FAQ", "onFaqClick"],
      ["mdi-currency-usd", "Rates", "onRatesClick"],
      ["mdi-login", "Log In", "onLoginClick"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
    onHomeClick: {
      type: Function,
      required: true,
    },
    onAboutClick: {
      type: Function,
      required: true,
    },
    onFaqClick: {
      type: Function,
      required: true,
    },
    onRatesClick: {
      type: Function,
      required: true,
    },
    onLoginClick: {
      type: Function,
      required: true,
    }
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    handleClick(functionName) {
      if (typeof this[functionName] === "function") {
        this[functionName]();
      } else {
        console.warn(`Function ${functionName} not defined`);
      }
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
